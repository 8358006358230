import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Layout from '../components/Layout';

const STYLE = {
  textAlign: 'initial',
  display: 'block',
  width: '100%',
  maxWidth: 1200,
  padding: '0 20px',
  margin: '20px auto',
};

export default function Privacy() {
  const { mdx } = useStaticQuery(
    graphql`
      query CookiePrivateQuery {
        mdx(frontmatter: { title: { eq: "Cookie政策" } }) {
          frontmatter {
            title
          }
          body
        }
      }
    `
  );
  return (
    <Layout current="cookieprivacy" subCurrent="cookieprivacy" type="privacy">
      <div className="privacy-md-container" style={STYLE}>
        <MDXProvider>
          <MDXRenderer>{mdx.body}</MDXRenderer>
        </MDXProvider>
      </div>
    </Layout>
  );
}
